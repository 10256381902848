import React, { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact: React.FC = () => {
  const form = useRef<HTMLFormElement | null>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .sendForm('service_fs07f3s', 'template_wazokbs', form.current!, 'f_RGdMKK7me4X2MEY')
      .then(
        () => {
          alert('Success! Your message has been sent.');
        },
        (error: { text: string }) => {
          alert('Failed to send. Please try again. ' + error.text);
        }
      );
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4">
      <div className="flex flex-col md:flex-row md:space-x-8 w-full max-w-6xl">
        <div className="flex flex-col items-start justify-center w-full md:w-1/2 p-8 text-white">
          <h1 className="text-3xl font-bold mb-4">Contact Me</h1>
          <p className="mb-4">Have an inquiry? Let's make it happen.</p>
          <div className="mb-2">
            <strong>Your Name</strong>
            <p className="text-gray-300">Alex</p>
          </div>
          <div className="mb-2">
            <strong>Email</strong>
            <p className="text-gray-300">alex@example.com</p>
          </div>
          <div className="mb-2">
            <strong>Phone</strong>
            <p className="text-gray-300">+1 234 567 8900</p>
          </div>
        </div>

        <form
          ref={form}
          onSubmit={sendEmail}
          className="bg-gray-800 p-6 rounded shadow-md w-full md:w-1/2"
        >
          <h2 className="text-2xl font-bold mb-4 text-center text-white">Get in Touch</h2>

          <label className="block text-sm font-medium text-gray-300">Name</label>
          <input
            type="text"
            name="user_name"
            required
            className="mt-1 block w-full p-2 bg-gray-700 text-white border border-gray-600 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />

          <label className="block text-sm font-medium text-gray-300 mt-4">Email</label>
          <input
            type="email"
            name="user_email"
            required
            className="mt-1 block w-full p-2 bg-gray-700 text-white border border-gray-600 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />

          <label className="block text-sm font-medium text-gray-300 mt-4">Message</label>
          <textarea
            name="message"
            required
            className="mt-1 block w-full p-2 bg-gray-700 text-white border border-gray-600 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />

          <button
            type="submit"
            className="mt-4 w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
