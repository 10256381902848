import React, { useState, useEffect } from 'react';

const Home: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    'http://localhost:3000/media/bg1.jpg',
    'http://localhost:3000/media/bg2.jpg',
    'http://localhost:3000/media/bg3.jpg',
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 3500);

    return () => clearInterval(timer);
  }, [slides.length]);

  const goToSlide = (index: number) => {
    setCurrentSlide(index);
  };

  return (
    <div className="bg-gray-900 text-white">
      <section id="home" className="h-screen relative overflow-hidden">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 bg-cover bg-center bg-no-repeat transition-opacity duration-1000 ${
              index === currentSlide ? 'opacity-100' : 'opacity-0'
            }`}
            style={{ backgroundImage: `url(${slide})` }}
          />
        ))}
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <h1 className="text-4xl md:text-6xl font-bold px-4 text-center">alx.edits</h1>
        </div>
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {slides.map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full ${
                index === currentSlide ? 'bg-white' : 'bg-gray-400'
              }`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
      </section>

      <section id="about" className="py-20 relative">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl md:text-5xl font-bold mb-16 text-center">About Me</h2>
          <div className="max-w-4xl mx-auto">
            <div className="space-y-6">
            <p className="text-xl leading-relaxed">
              Hey, I'm Alex. I'm 18, and I’ve been into photography and videography for a couple of years now. It started with shooting cars for Instagram, which is still what I do a lot.  I’ve also been doing some real estate work, mixing things up a bit.
            </p>
            <p className="text-xl leading-relaxed">
              Whether it's cars or properties, my goal is to capture clean, high-quality shots that look great and tell a simple story. I keep it straightforward—no over-the-top stuff, just visuals that stand out.
            </p>

              <div className="mt-12 text-center">
                <a href="contact" className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 text-lg">
                  Contact me!
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="py-20 bg-gray-900">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl md:text-5xl font-bold mb-16 text-center">Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <ServiceCard 
              title="Automotive Photography"
              description="From classic beauties to modern supercars, I capture the essence of automobiles with precision and artistry. My shoots include dynamic action shots, detailed interiors, and showroom-quality stills."
              image="/path-to-automotive-image.jpg"
            />
            <ServiceCard 
              title="Real Estate Photography"
              description="Showcase properties in their best light with my professional real estate photography services. I use advanced techniques to highlight spatial features and create inviting imagery that sells."
              image="/path-to-real-estate-image.jpg"
            />
            <ServiceCard 
              title="Video Production"
              description="Bring your story to life with high-quality video content. From promotional videos for car dealerships to virtual tours for real estate, I offer end-to-end video production services."
              image="/path-to-video-production-image.jpg"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

interface ServiceCardProps {
  title: string;
  description: string;
  image: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ title, description, image }) => (
  <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition duration-300">
    <img src={image} alt={title} className="w-full h-48 object-cover" />
    <div className="p-6">
      <h3 className="text-2xl font-semibold mb-4">{title}</h3>
      <p className="text-gray-300 mb-4">{description}</p>
      <a href="#contact" className="text-blue-400 hover:text-blue-300 font-semibold">Learn More →</a>
    </div>
  </div>
);

export default Home;
