// src/components/Navbar.tsx

import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="fixed top-4 left-4 right-4 bg-gray-800 bg-opacity-80 text-white p-4 rounded-lg z-50">
      <div className="container mx-auto px-4 flex justify-between items-center">
        <Link to="/" className="text-xl font-bold">alx.edits</Link>
        <div className="hidden md:flex space-x-4">
          <a href="/#home">Home</a>
          <Link to="/portfolio">Portfolio</Link>
          <a href="/#about">About</a>
          <a href="/#services">Services</a>
          <Link to="/contact">Contact</Link>
        </div>
        <button 
          className="md:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          Menu
        </button>
      </div>
      {isMenuOpen && (
        <div className="md:hidden mt-4">
          <a href="/#home" className="block py-2">Home</a>
          <Link to="/portfolio" className="block py-2">Portfolio</Link>
          <a href="/#about" className="block py-2">About</a>
          <a href="/#services" className="block py-2">Services</a>
          <Link to="/contact" className="block py-2">Contact</Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;