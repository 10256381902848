import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white p-4 mt-8 rounded-t-lg relative">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        
        {/* Copyright Section */}
        <div className="text-center md:text-left mb-4 md:mb-0">
          <a href="https://www.instagram.com/alx.edits" >alx.edits</a>
        </div>
        
        {/* Centered Social Media Links */}
        <div className="absolute left-1/2 transform -translate-x-1/2 flex space-x-4">
        <a
            href="https://www.instagram.com/alx.edits"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white py-2 px-4 hover:underline"
          >
            IG
          </a>
          <a
            href="https://www.tiktok.com/@alx.editos"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white py-2 px-4 hover:underline"
          >
            TT
          </a>
        </div>
        
        {/* Website by Sage Media Section */}
        <div className="text-center md:text-left mb-4 md:mb-0 text-gray-400">
          <a href="https://www.sage-media.co" target='_blank' rel='noopener noreferrer' className='hover:underline'>Website by <span className="font-handwritten">Sage Media</span></a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
