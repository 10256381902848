import React from 'react';

const Portfolio: React.FC = () => {
  // Media for Car Shoot 1
  const carShoot1 = [
    { src: 'http://localhost:3000/media/portfolio/shoot1/1.mp4', type: 'video' },
    { src: 'http://localhost:3000/media/portfolio/shoot1/2.jpg', alt: 'Car Shoot 1 - Image 2', type: 'image' },
    { src: 'http://localhost:3000/media/portfolio/shoot1/3.jpg', alt: 'Car Shoot 1 - Image 3', type: 'image' },
    { src: 'http://localhost:3000/media/portfolio/shoot1/4.jpg', alt: 'Car Shoot 1 - Image 4', type: 'image' },
    { src: 'http://localhost:3000/media/portfolio/shoot1/5.jpg', alt: 'Car Shoot 1 - Image 5', type: 'image' },
    { src: 'http://localhost:3000/media/portfolio/shoot1/6.jpg', alt: 'Car Shoot 1 - Image 6', type: 'image' },
    { src: 'http://localhost:3000/media/portfolio/shoot1/7.jpg', alt: 'Car Shoot 1 - Image 7', type: 'image' },
    { src: 'http://localhost:3000/media/portfolio/shoot1/8.jpg', alt: 'Car Shoot 1 - Image 8', type: 'image' },
    { src: 'http://localhost:3000/media/portfolio/shoot1/9.jpg', alt: 'Car Shoot 1 - Image 9', type: 'image' },


  ];

  return (
    <div className="flex flex-wrap justify-center items-center gap-4 p-6 min-h-screen mt-20"> {/* Added margin-top */}
      {carShoot1.map((media, index) => (
        <div key={index} className="flex-shrink-0 w-80 p-2"> {/* Adjusted width to 80 (20rem or 320px) */}
          {media.type === 'video' ? (
            <video 
              src={media.src}

              className="w-full h-auto object-cover rounded-lg"
              autoPlay
              loop
              muted
              controls={false} // Set to true if you want to show controls
            />
          ) : (
            <img 
              src={media.src} 
              alt={media.alt} 
              className="w-full h-auto object-cover rounded-lg" // Responsive image styling
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default Portfolio;
